<template>
  <div id="received" class="text-center my-12">
    <h2 class="display-1 mb-6">Thank you for your message!</h2>
    <p class="mb-10">We will respond to you shortly.</p>
    <v-btn to="/" color="primary" class="white--text">Go back to home</v-btn>
  </div>
</template>

<script>
export default {
  name: "Received",
};
</script>

<style></style>
